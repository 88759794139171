@use "../../scss/variables" as *;

.ErrorPage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 178rem;
}

.errPage{
    .errSubtitle{
        font-family:$fontUI;
        font-style: normal;
        font-weight: 600;
        font-size: 16rem;
        line-height: 24rem;
        color: $black-blue;
    }

    .errTitle{
        font-family: $fontText;
        font-style: normal;
        font-weight: 600;
        font-size: 60rem;
        line-height: 72rem;
        letter-spacing: -0.02em;
        color: $gray-900;
        margin-top: 12rem;
    }

    .errText{
        color: $gray-500;
        font-style: normal;
        font-weight: 400;
        font-size: 20rem;
        line-height: 30rem;
        margin-top: 24rem;
    }
}

.errContainerBottom{
    display: flex;
    align-items: center;
    margin-top: 45rem;
}

.buttonBlue{
    height: 100%;
    background-color: $blue;
}

.buttonWhite{
    background-color: $white;
    color: $gray-700;
    border: 1px solid $gray-300;
    margin-right: 12rem ;
    object{
        transition: .3s linear;
        transform: translateX(0);
    }

    &:hover{
        background-color: $gray-200;

        object{
            transform: translateX(-10%);
        }
    }
}