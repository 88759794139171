$purp-blue: #7285e4;
$gradient: linear-gradient(45deg, #ff4e6b 0%, #4c92d9 100%);
$txt-color: #2c2d2e;
$white: #ffffff;
$sidebar: #f3f3f5;
$lg-gray: #ecedf0;
$sg-gray: #e0e1e6;
$wg-gray: #e6e7eb;
$gray-20: #f9f9fa;
$gray-40: #F3F3F5;
$gray-50: #f9fafb;
$gray-60: #ECEDF0;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;

$data-gray: #919399;
$gray-iron-500: #70707B;
$gray-iron-700: #3F3F46;
$purple: #528bff;
$switch: #bfc1c7;
$success-400: #47cd89;
$pink: #2ed3b7;
$grin: #a3a3a3;

$blue-25: #F2FAFF;
$blue-50: #E5F4FF;
$blue-100: #CCEAFF;
$blue-200: #99D5FF;
$blue-300: #66BFFF;
$blue-400: #33AAFF;
$blue-500: #007ED9;
$blue-600: #006CB9;
$blue-700: #005999;
$blue-800: #003C66;
$blue-900: #001E33;
$blue-950: #000F1A;

$red-25: #FFFBFA;
$red-50: #FEF3F2;
$red-100: #FEE4E2;
$red-200: #FECDCA;
$red-300: #FDA29B;
$red-400: #F97066;
$red-500: #F04438;
$red-600: #D92D20;
$red-700: #B42318;
$red-800: #912018;
$red-900: #7A271A;
$red-950: #55160C;

$green-25: #FAFDF7;
$green-50: #EDFCF2;
$green-100: #E6F4D7;
$green-200: #CEEAB0;
$green-300: #ACDC79;
$green-400: #86CB3C;
$green-500: #669F2A;
$green-600: #4F7A21;
$green-700: #3F621A;
$green-800: #335015;
$green-900: #2B4212;
$green-950: #1A280B;

$orange-25: #FFFCF5;
$orange-50: #FFFAEB;
$orange-100: #FEF0C7;
$orange-200: #FEDF89;
$orange-300: #FEC84B;
$orange-400: #FDB022;
$orange-500: #F79009;
$orange-600: #DC6803;
$orange-700: #B54708;
$orange-800: #93370D;
$orange-900: #7A2E0E;
$orange-950: #4E1D09;

$blue: $blue-500;

$footer: #046;

$border-color: rgba(0, 16, 61, 12%);
$blue-blue: $blue-600;
$black-blue: $blue-600;
$orange: $orange-500;
$weekend: #eb5757;
$red: #ed0a34;
$redButton: #d92d20;
$error-500: $red-500;
$lg-blue: #37aee2;
$lg-blue-100: #e0f2fe;
$text-gray: #919399;
$black-btn: #2c2d2e;
$ros-500: #f63d68;
$ros-600: #e31b54;
$black: $black-btn;
$box-shadow: 0px 4px 12px rgba(5, 13, 51, 0.2);
$box-shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
$box-shadow-lg:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
$box-shadow-gradient: 0px 4px 20px rgba(188, 166, 255, 0.8);
$gradient: linear-gradient(90deg, #ff4e6b 0%, #4c92d9 100%);
$header-height: 8rem;
$border-radius: 30px;
$mobile-width: 800px;
$tablet-width: 1023px;
$laptop-width: 1439px;
$hd-width: 1920px;

// fonts

// @font-face {
//     font-family: "GolosUi";
//     src:
//         url("../assets/fonts/Golos-UI-VF/golos-ui_vf.woff2") format("woff2"),
//         url("../assets/fonts/Golos-UI-VF/golos-ui_vf.woff") format("woff");
// }
// @font-face {
//     font-family: "GolosText";
//     src:
//         url("../assets/fonts/Golos-Text-VF/golos-text_vf.woff2") format("woff2"),
//         url("../assets/fonts/Golos-Text-VF/golos-text_vf.woff") format("woff");
// }

@font-face {
    font-family: 'Golos';
    src:
            url('../assets/fonts/Golos/Golos-Text_Regular.woff2') format('woff2'),
            url('../assets/fonts/Golos/Golos-Text_Regular.woff') format('woff');
    font-style: normal;
  }
  @font-face {
    font-family: 'Golos';
    src:
            url('../assets/fonts/Golos/Golos-Text_DemiBold.woff2') format('woff2'),
            url('../assets/fonts/Golos/Golos-Text_DemiBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'PT_Root';
    src:
            url('../assets/fonts/PT_Root/pt-root-ui_vf.woff2') format('woff2'),
            url('../assets/fonts/PT_Root/pt-root-ui_vf.woff') format('woff'),
            url('../assets/fonts/PT_Root/pt-root-ui_vf.ttf') format("truetype");
    font-style: normal;
  }

  $fontUI: "PT_Root", sans-serif;
  $fontText: "Golos", sans-serif;
  