@use "../../scss/" as *;

.rightSidbarContainer{
    position: fixed;
    right: -100%;
    top: 0;
    background-color: $gray-40;
    padding: 16rem 24rem;
    max-width: 532rem;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    transition: .5s ease-in-out;
    .sidebarHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .sidebarTitle {
        display: block;
        @include fnt(16, 24, $black, 700);
        margin: 16rem 0;
    }
    .sidebarClosedButton{
        display: flex;
        align-items: center;
        gap: 8rem;
        cursor: pointer;
        max-width: 98rem;
        img{
            background-color: $white;
            border-radius: 50%;
            padding: 4rem;
        }

        p{
            @include fnt(14, 24, $weight: 400, $font: $fontText)
        }
    }
    .sidebarAddPaymentButton {
        @include btn()
    }

    &.activeBar{
        right: 0;
    }

    h1{
        margin-top: 16rem;
    }

    .containerPage{
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        height: unset ;
        @include scroll();
        .tablRow {
            td {
                padding: 6rem 0;
                a {
                
                }
            }
        }
    }
}