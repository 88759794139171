@use "../../scss/" as *;

.integrationContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16rem 12rem;

    &:nth-child(2n){
        background-color: $gray-100;
    }

    p{
        @include fnt(14, 24, $color: $black, $weight:600)
    }

    .buttonIntegration{
        background-color: $blue;
        padding: 6rem 12rem;
        border-radius: 8rem;
    }
}