@use "../../scss/" as *;
.sbButton {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    background-color: $sg-gray;
}

.button__container {
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem 16rem;
    border-radius: 8rem;
    border: none;
    cursor: pointer;
    position: relative;
    @include fnt(14, $color: $white, $height: 16);
    transition: 0.1s linear;
    &.iconContainer {
        padding: 8rem 24rem 8rem 12rem;
    }
    object {
        margin-right: 10rem;
        width: 24rem;
        height: 24rem;
        pointer-events: none;
    }
    .circleNumber {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -10rem;
        background-color: $white;
        border-radius: 50%;
        width: 22rem;
        height: 22rem;
        border: 2rem solid #0cc;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fnt(14, 24, $weight: 500, $color: $txt-color);
    }
    &:hover {
        background-color: $blue;
    }
}

.deleteButton__container {
    background-color: inherit;
    border: none;
    color: $red;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}

@mixin button() {
    display: flex;
    align-items: center;
    width: fit-content;
    border: none;
    background-color: transparent;
    transition:
        background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
    cursor: pointer;
    &._icon-left {
        flex-direction: row-reverse;
    }
    span {
        transition:
            color 0.2s ease-in-out,
            font-variation-settings 0.2s ease-in-out;
        font: {
            family: $fontUI;
            variation-settings: "wght" 500;
        }
    }
    svg {
        path,
        line,
        polyline,
        polygon,
        rect,
        circle,
        ellipse {
            transition: stroke 0.2s ease-in-out;
        }
    }
    &._reverse {
        flex-direction: row-reverse;
    }
    &._disabled,
    &[disabled] {
        opacity: 0.3;
        pointer-events: none;
    }
}

@mixin button-sizes() {
    font-size: 14rem;
    line-height: 20rem;
    padding: 10rem;
    border-radius: 8rem;
    span {
        padding: 0 6rem;
        font-size: 14rem;
        line-height: 20rem;
    }
    svg {
        @include size(20);
    }
    &._round {
        padding: 4rem;
        border-radius: 14rem;
        span {
            font-size: 12rem;
            line-height: 16rem;
            font-variation-settings: "wght" 400;
            padding: 0 4rem;
        }
        svg {
            @include size(16);
        }
    }
    &._xs {
        padding: 4rem;
        span {
            font-size: 12rem;
            line-height: 16rem;
            padding: 0 2rem;
        }
        svg {
            @include size(16);
        }
    }
    &._sm {
        padding: 8rem;
        span {
            font-size: 14rem;
            line-height: 20rem;
            padding: 0 4rem;
        }
    }
    &._lg {
        padding: 12rem;
        span {
            font-size: 16rem;
            line-height: 20rem;
            padding: 0 8rem;
        }
    }
    &._xl {
        padding: 18rem;
        span {
            font-size: 18rem;
            line-height: 24rem;
            padding: 0 12rem;
        }
        svg {
            @include size(24);
        }
    }
    &._block {
        width: 100%;
        justify-content: center;
    }
    &._mobile-block {
        @include tablet {
            width: 100%;
            justify-content: center;
        }
    }
}

@mixin btn($color, $hover) {
    @include button();
    background: $color;
    span {
        color: #fff;
    }
    svg * {
        stroke: #fff;
    }
    &:hover {
        background-color: $hover;
    }
    &._active,
    &:active {
        background-color: $color;
        box-shadow:
            0px 1px 2px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px $gray-100;
    }
    @include button-sizes();
}

@mixin btn-secondary($color, $hover, $border) {
    @include button();
    background-color: #ffffff;
    border: 1px solid $border;
    span {
        color: $color;
    }
    svg * {
        stroke: $color;
    }
    &._active,
    &:hover {
        background-color: $hover;
        border-color: $border;
    }
    @include button-sizes();
}

@mixin btn-teritary($color) {
    @include button();
    span {
        color: $color;
    }
    svg * {
        stroke: $color;
    }
    &._active,
    &:hover {
        span {
            font-variation-settings: "wght" 700;
        }
    }
    @include button-sizes();
    border-radius: 0;
}

@mixin btn-outline($color) {
    @include button();
    background-color: transparent;
    border: 1px solid $color;
    span {
        color: $color;
    }
    svg * {
        stroke: $color;
    }
    &._active,
    &:hover {
        background-color: $color;
        span {
            color: #fff;
        }
        svg * {
            stroke: #fff;
        }
    }
    @include button-sizes();
}

@mixin btn-link($color, $hover) {
    @include button();
    @include button-sizes();
    span {
        color: $color;
    }
    svg * {
        stroke: $color;
    }
    ._active,
    &:hover {
        span {
            color: $hover;
            font-variation-settings: "wght" 700;
        }
        svg * {
            stroke: $hover;
        }
    }
    border-radius: 0;
    padding: 0 !important;
}

.btn {
    @include btn($blue-600, $blue-700);
    &._gray {
        @include btn($gray-400, $gray-500);
    }
    &._green {
        @include btn($green-500, $green-600);
    }
    &._orange {
        @include btn($orange-500, $orange-600);
    }
}

.btn-secondary {
    @include btn-secondary($blue-600, $blue-50, $blue-300);
    &._gray {
        @include btn-secondary($gray-700, $gray-100, $gray-300);
    }
    &._grayMapIconButton {
        @include btn-secondary($gray-700, $gray-100, $gray-300);
        &._active {
            background-color: $blue-600;
            border-color: $blue-600;
            span {
                color: #fff;
            }
            svg * {
                stroke: #fff;
            }
        }
    }
    &._green {
        @include btn-secondary($green-700, $green-50, $green-300);
    }
    &._orange {
        @include btn-secondary($orange-700, $orange-50, $orange-300);
    }
}

.btn-teritary {
    @include btn-teritary($blue-600);
    &._gray {
        @include btn-teritary($gray-700);
    }
}

.btn-outline {
    @include btn-outline($blue-600);
    &._gray {
        @include btn-outline($gray-600);
    }
    &._green {
        @include btn-outline($green-600);
    }
    &._orange {
        @include btn-outline($orange-600);
    }
}

.btn-link {
    @include btn-link($blue-400, $blue-700);
    &._black {
        @include btn-link($gray-700, $gray-900);
    }
    &._gray {
        @include btn-link($gray-400, $gray-700);
    }
}

.btn-gr {
    @include button();
    background: linear-gradient(90deg, #e31b53 0%, #2e90fa 50%, #f63d68 50%, #53b1fd 100%);
    background-size: 200%;
    background-position: 100%;
    transition: background-position 0.3s ease-in-out;
    span {
        color: #fff;
    }
    svg * {
        stroke: #fff;
    }
    &:hover {
        background-position: 0%;
    }
    &:active {
        background-color: $blue-600;
        box-shadow:
            0px 1px 2px rgba(16, 24, 40, 0.05),
            0px 0px 0px 4px $gray-100;
    }
    @include button-sizes();
}
