@use "../../scss/" as *;

.sidebar {
  // position: fixed;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 180rem;
  height: 100vh;
  background-color: $gray-iron-700;
  transition: 0.1s linear;
  user-select: none;
  &.active {
    width: 44rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 12rem 0 12rem;
    height: 80rem;
    background-color: inherit;

    .button__sideBar {
      width: 6rem;
      height: 12rem;
      opacity: 0.6;
    }
    &.active {
      flex-direction: column;
      height: 80rem;
      padding: 0 12rem;
      .button__sideBar {
        transform: rotateZ(180deg);
        width: 6rem;
        height: 12rem;
        margin: 16rem 0 16rem 0;
      }
      img {
        width: 24rem;
        height: 24rem;
      }
    }
  }
  .buttonSide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    padding: 0 8rem 8rem;

    &.active {
      justify-content: center;
      padding: 8rem;
    }

    img {
      transform: rotateZ(180deg);

      &.active {
        transform: rotateZ(0deg);
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    @include scroll();
    .sidebar__nav {
      padding-bottom: 12rem;
      border-bottom: 1rem solid rgba(255, 255, 255, 0.15);
      margin-bottom: 12rem;
    }
  }
  a {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    color: $white;
    opacity: 0.5;
    padding: 4rem 12rem;
    img {
      width: 24rem;
      height: 24rem;
      margin-right: 8rem;
    }
    &:hover {
      background-color: $gray-iron-500;
    }
    &.active {
      background-color: $gray-iron-500;
      opacity: 1;
      .display {
        @include fnt(14, $color: $white, $weight: 400, $height: 16);
      }
    }
  }
  .sidebar__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16rem 35rem 16rem 12rem;
    height: 66rem;
    opacity: 1;
    img {
      width: 92rem;
      height: 24rem;
      opacity: 1;
    }
    .subText {
      width: 100%;
      margin-top: 3rem;
      @include fnt(10, $weight: 500, $color: $white, $height: 10);

      &.active {
        display: none;
      }
    }
    &.miniBar {
      background-color: none;
      padding: 0;

      img {
        margin: 0;
      }
    }
  }
  .sidebar__footerLink {
    padding: 12rem;
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;

    .userName {
      font-weight: unset;
      @include fnt(14, $color: $white);
    }
    .userProf {
      @include fnt(10, $color: $white, $weight: 400, $height: 12);
    }
    .userOrg {
      @include fnt(10, 12, $color: $white, $weight: 400, $opacity: 0.5);
    }
    .logoutButton {
      padding: 0;
      opacity: 1;
      margin: 0;
      height: auto;
      cursor: pointer;

      p {
        @include fnt(12, $color: $white);
        opacity: 0.5;
      }
    }

    .footerUserData {
      &.active {
        display: none;
      }
    }
  }
  .display {
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.1s linear;
    @include fnt(14, $color: $white, $weight: 450, $height: 16);
  }
  .hidden {
    position: absolute;
    transform: translateY(-200%);
    pointer-events: none;
    transition: opacity 0.1s linear;
  }
  @include mobile {
    position: fixed;
  }
}
.backCollectButton {
  height: 32rem !important;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }

  &.miniBar {
    a {
      background-color: $white;
    }
    span {
      display: none;
    }
  }
}

.control-pannel_sidebar {
  .sidebar {
    background-color: $gray-800;
    a {
      .display {
        color: white;
      }
      &.active {
        background-color: $gray-600;
        .display {
          font-weight: 600;
        }
      }
    }
  }
}

.backdrop {
    display: none;
    @include mobile {

    
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
  overflow: auto;
  z-index: 90;
  display: block;
  pointer-events: unset;
  opacity: 1;
  &.active {
    display: none;
    pointer-events: none;
    opacity: 0;
  }
}
}

.linkMobileApp{
  padding: 0 !important;
  opacity: .8  !important;
  height: 28rem !important;

  img{
    background-color: $gray-400;
    border-radius: 100%;
    padding: 4rem;
  }

  &:hover{
    background-color: inherit !important;
    opacity: 1 !important;
  }

  .linkMobileText{
    h4{
      @include fnt(12, 14, $weight:600, $color: $white);
      -webkit-font-smoothing: antialiased;
    }
    h6{
      @include fnt(12, 14, $weight:400, $color: $white);
      -webkit-font-smoothing: antialiased;
    }
  }
}

@media (max-width: 600px) {
  // .sidebar__box{
  //     width: 100%;
  //     position: fixed;
  //     height: 40rem;
  //     bottom: 10rem;
  //     z-index: 100;

  //     span{
  //         display: none;
  //     }

  //     &.active{
  //         width: 100%;
  //         position: fixed;
  //         height: 40rem;
  //         bottom: 10rem;
  //         z-index: 100;
  //     }
  // }

  // .sidebar{
  //     width: 100%;
  //     height: auto;
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     &.active{
  //         width: 100%;
  //         height: auto;
  //         display: flex;
  //         flex-direction: row;
  //         align-items: center;
  //     }
  //     a{
  //         img{
  //             width: 40rem;
  //             height: 40rem;
  //         }
  //     }
  //     .sidebar__header{
  //         height: 40rem;
  //     }

  //     .buttonSide {
  //         display: none;
  //     }

  //     .footerUserData{
  //         display: none;
  //     }
  //     .sidebar__footerLink{
  //         padding: 0;
  //         .logoutButton{
  //             padding: 0 12rem;
  //             display: flex;
  //             img{
  //                 width: 50rem;
  //                 height: 50rem;
  //             }
  //         }
  //     }

  // }
  // .sidebar__container{
  //     flex-direction: row;

  //     .sidebar__nav{
  //         display: flex;
  //         border: 0;
  //         margin: 0;
  //         padding: 0;

  //         a{
  //             img{
  //                 width: 50rem;
  //                 height: 50rem;
  //             }
  //         }
  //     }
  // }
  // .sidebar_center{
  //     display: flex;
  //     align-items: center;
  // }
}
