@use "../../scss" as *;

.mapPage {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mapRegionContainer {
    display: flex;
    grid-gap: 8rem;
}

.mapPageContainer {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    .ymaps-2-1-79-map {
        width: 100% !important;
    }
    &.active {
        .ymaps-2-1-79-map {
            width: 100% !important;
        }
    }
}
