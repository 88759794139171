@use "../../scss/" as *;

.dropDownContainer{
    background-color: $sidebar;
    margin-top: 12rem;
    padding: 10rem 12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8rem;
    cursor: pointer;

    .nameDropDown{
        display: flex;
        justify-content: space-between;
        align-items: center;

        img{
            width: 24rem;
            height: 24rem;
        }

        h1{
            margin-left: 8rem;
            @include fnt(16, 24,$black-btn, 500);
        }

        .circleNumber{
            width: 24rem;
            height: 24rem;
            border-radius: 50%;
            border: 1rem solid $text-gray;
            display: flex;
            justify-content: center;
            align-items: center;
            @include fnt(14, 24,$black-btn, 500);

            img{
                width: 16rem;
                height: 16rem;
            }
        }
    }

    .dateDropDown{
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 16rem;
    }

    .containerDate{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        span{
            @include fnt(10, 12,$text-gray, 500);
        }

        p{
            @include fnt(12, 24,$text-gray, 500);
        }
    }

}

.blockedDrop{
    opacity: .5;
    pointer-events: none;
}

.checkContainer{
    padding: 16rem 24rem;
}

.imageThumbDropDown{
    grid-template-columns: repeat(11, 1fr);
}

.checkTitle{
    margin-bottom: 16rem;
    @include fnt(16, 24,$black-btn, 600);
}

.buttonCheckContainer{
    display: flex;
    align-items: center;
    grid-gap: 8rem;

    .createButton{
        @include fnt(14, 24,$white, 500);
    }

    .createButtonGreen{
        @include fnt(14, 24,$white, 500);
        background-color: $green-400;
    }
  
}

.repeatButton{

    @include fnt(14, 24,$white, 500);
    background-color: $red;
    margin-top: 6rem;

    &:hover{
        background-color: $redButton;
    }
}

.violationContainer{
    display: flex;
    align-items: center;
    grid-gap: 4rem;

    h1{
        @include fnt(14, 24,$text-gray, 400);
    }
    h4{
        @include fnt(14, 24,$txt-color, 600);
    }
}

.containerModalDropDown{
    background-color: $white;
    padding: 28rem 32rem;
    width: 100%;
    height: 100%;
    grid-gap: 16rem;
}

.checkModalImage{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    img{
        width: 64rem !important;
        height: 64rem !important;
    }
}

.containerInputCheck{
    display: grid;
    gap: 16rem;
    height: 100%;
    align-items: center;
}

.formContainerModal{
    display: grid;
    grid-gap: 16rem;
}

.buttonCheckContainerModal{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8rem;
    margin-top: 16rem;

    .createButtonGrey{
        @include fnt(14, 16,$txt-color, 500);
        background-color: $gray-60;
    }
}

.stagesInspectionContainer{
    display: flex;
    flex-direction: column;
    grid-gap: 8rem;
    margin-bottom: 16rem;

    h4{
        @include fnt(14, 24, $weight: 400, $font: $fontUI, $color: $txt-color);
    }
}

.statusStagesComplete{
    display: flex;
    align-items: center;
    grid-gap: 8rem;

    .statusStagesContainer{
        display: flex;
        align-items: center;
        grid-gap: 4rem;
        .statusStagesText{
            @include fnt(14, 24, $weight: 600, $font: $fontUI, $color: $success-400);
        }
       
   
    }
    .datenameStagesText{
        display: flex;
        align-items: center;
        grid-gap: 8rem;
        p{
            padding-left: 8rem;
            border-left: 1rem solid $border-color;
            @include fnt(14, 24, $weight: 400, $font: $fontUI, $color: $text-gray);
        }
    }
   
}

.containerLAlertModal{
    display: flex;
    flex-direction: column;
    grid-gap: 8rem;
    .alertTextModal{
        display: flex;
        align-items: center;
        @include fnt(14, 20, $weight: 400, $font: $fontText, $color: $red);
    }

    .textAlertModal{
        @include fnt(14, 20, $weight: 400, $font: $fontText, $color: $txt-color);
    }

    .createButtonGreen{
        background-color: $green-400;
    }
}

.formStage{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 16rem;

    .col-1{
        grid-column: 1;
    }
}