@use "../../scss/" as *;

.mapFilterContainer{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.conainerStatusSub{
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.containerSubStatus{
    display: flex;
    flex-direction: column;
    gap: 4rem;

    margin-left: 16rem;
}

.containerFilterCheckbox{
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;
}
.containerCategory{
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;
}

.modalCheck{
    width: 280rem;
    background-color: $white;
    padding: 24rem;
    display: flex;
    gap: 16rem;
    flex-direction: column;
    border-radius: 12rem;
    box-shadow: 0rem 4rem 16rem 0rem rgba(0, 16, 61, 0.16);
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    max-height: 600rem;
}

.containerToggle{
    flex-direction: row-reverse;
    justify-content: space-between;

    .checkboxText {
        margin: 0;
        @include fnt(14, 16, $txt-color, $font: $fontText);
    }
    
}

.toggleText{
    margin-left: 0;
}

.microdistrictContainer{
    display: flex;
    align-items: center;
    background-color: $white;
    box-shadow: 0rem 4rem 16rem 0rem rgba(0, 16, 61, 0.16);
    border-radius: 8rem;
    max-width: 180rem;
    padding: 8rem;

    img{
        width: 32rem;
        height: 32rem;
    }

    .microdistrictText{
        margin-left: 10rem;

        .smaller-width {
            max-width: 120rem; /* Укажите желаемую ширину */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        p{
            @include fnt(10, 12);
        }

        h1{
            @include fnt(16, 20,$font:$fontText, $weight: 600, $color: $txt-color);
        }
    }
}

.titleGroupFilter{
    @include fnt(12, 20,$font:$fontText, $weight: 500, $color: $text-gray);
}

.toggleContainerMapFilter{
    display: flex;
    flex-direction: column;
    grid-gap: 12rem;
}

@media (max-width: 600px) {
    .mapFilterContainer{
        padding: 0;
        transform: scale(.8);
        top: -44rem;
        left: -20rem;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &.openFilter{
            pointer-events: all;
        }
    }

    .modalCheck{
       display: none;
       &.openFilter{
        display: block;
       }
    }

    .buttonMobileilter{
        position: fixed;
       
        .button__container{
            padding: 2rem 8rem;
            pointer-events: all;

            object{
                transform: scale(1);
            }
        }
    }
}