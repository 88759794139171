@use "./scss/" as *;
@font-face {
  font-family: "GolosUi";
  src:
    local("Golos-UI-VF"),
    url("./assets/fonts/Golos-UI-VF/golos-ui_vf.woff") format("woff"),
    url("./assets/fonts/Golos-UI-VF/golos-ui_vf.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GolosText";
  src:
    local("Golos-Text-VF"),
    url("./assets/fonts/Golos-Text-VF/golos-text_vf.woff") format("woff"),
    url("./assets/fonts/Golos-Text-VF/golos-text_vf.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Golos";
  src:
    url("./assets/fonts/Golos/Golos-Text_Regular.woff2") format("woff2"),
    url("./assets/fonts/Golos/Golos-Text_Regular.woff") format("woff");
  font-style: normal;
}
@font-face {
  font-family: "Golos";
  src:
    url("/assets/fonts/Golos/Golos-Text_DemiBold.woff2") format("woff2"),
    url("./assets/fonts/Golos/Golos-Text_DemiBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "PT_Root";
  src:
    url("./assets/fonts/PT_Root/pt-root-ui_vf.woff2") format("woff2"),
    url("./assets/fonts/PT_Root/pt-root-ui_vf.woff") format("woff"),
    url("./assets/fonts/PT_Root/pt-root-ui_vf.ttf") format("truetype");
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 1.2px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  // @include hd {
  //   font-size: calc((120 * 100vw / 1920 / 100));
  //   width: calc((100 * 1920 / 100));
  // }
  @include laptop {
    font-size: calc((100 * 100vw / 1024 / 100));
    width: calc((100 * 700 / 100));
  }
  @include tablet {
    font-size: calc((100 * 100vw / 700 / 100));
    width: calc((100 * 700 / 100));
  }
  @include mobile {
    font-size: calc((100 * 100vw / 375 / 100));
    width: calc((120 * 375 / 100));
  }

  @include scroll();
}

/* Стилизация полосы прокрутки (браузеры, не поддерживающие WebKit) */

body {
  @include scroll();
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}

body {
  font-family: "GolosVF";
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  background-color: $white;
  color: $txt-color;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
}

iframe {
  display: none !important;
}

a {
  text-decoration: none;
  color: unset;
}

button,
input {
  outline: 0;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
}

iframe {
  border: 0;
}

.container {
  max-width: 1660px;
  margin: auto;
}

.d-none {
  display: none !important;
}

$indents: 0, 2, 3, 4, 8, 12, 16, 24, 28, 32, 48, 54;
.m {
  @each $margin in $indents {
    &-#{$margin} {
      margin: #{$margin}rem !important;
    }
    &t-#{$margin} {
      margin-top: #{$margin}rem !important;
    }
    &b-#{$margin} {
      margin-bottom: #{$margin}rem !important;
    }
    &l-#{$margin} {
      margin-left: #{$margin}rem !important;
    }
    &r-#{$margin} {
      margin-right: #{$margin}rem !important;
    }
    &y-#{$margin} {
      margin-top: #{$margin}rem !important;
      margin-bottom: #{$margin}rem !important;
    }
    &x-#{$margin} {
      margin-right: #{$margin}rem !important;
      margin-left: #{$margin}rem !important;
    }
  }
}

.p {
  @each $padding in $indents {
    &-#{$padding} {
      padding: #{$padding}rem !important;
    }
    &t-#{$padding} {
      padding-top: #{$padding}rem !important;
    }
    &b-#{$padding} {
      padding-bottom: #{$padding}rem !important;
    }
    &l-#{$padding} {
      padding-left: #{$padding}rem !important;
    }
    &r-#{$padding} {
      padding-right: #{$padding}rem !important;
    }
    &y-#{$padding} {
      padding-top: #{$padding}rem !important;
      padding-bottom: #{$padding}rem !important;
    }
    &x-#{$padding} {
      padding-right: #{$padding}rem !important;
      padding-left: #{$padding}rem !important;
    }
  }
}

.section {
  padding: 0 2rem;
}

.section__header {
  @include flex(center, space-between);
}

.main {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @include mobile {
    grid-template-columns: 100vw;
  }
}
.page {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  @include mobile {
    padding-left: 44rem;
  }
}

.flex {
  display: flex;
}

.justify-sb {
  display: flex;
  justify-content: space-between;
}

h1 {
  font-size: 16rem;
}

body,
p,
span,
label,
a {
  @include fnt();
}

.containerPage {
  width: 100%;
  height: 90%;
  overflow-x: auto;

  @media (max-width: 600px) {
    // margin-top: 80rem;
    padding: 8rem !important;
  }
}

.gridFull {
  grid-column: 1/3;
}

._disable {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontUI;
}
