.microdistrict-reports-page-main-content {
  width: 396rem;

  display: flex;
  flex-direction: column;

  gap: 16rem;

  padding: 8rem 24rem;
}

