@use '../../../scss/' as *;

.fileContainer{
    width: 340rem;
    height: 40rem;
    display: flex;
    align-items: center;

    &:hover .file__name{
        animation: marquee 6s ease-in-out infinite;
    }

    .file__name-wrap{
        display: flex;
        align-items: center;
        flex-direction: row;
        overflow: hidden;
    }

    .file__icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width:24rem;
        height: 24rem;
        margin-right: 4rem;
    }

    .file__name{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250rem;
        white-space: nowrap;
        

        @include fnt(14, $weight: 500, $color: $gray-700, $height: 20);
      
    }
    

    .file__trash{
        width: 24rem;
        height: 24rem;
        padding: 8rem;
        margin-left: 8rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .file__trash{
        width: 40rem;
        height: 100%;
        padding: 8rem;
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }
    }

    .file_subText{
        display: flex;
        align-items: center;

        .file__size{
            margin-right: 4rem;
            @include fnt(12, $weight: 500, $color: $text-gray)
        }
    }

    .containerType{
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .file__type{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 27rem;
            height: 20rem;
            @include fnt(10, $color: $gray-500);
            text-transform: uppercase;
            padding: 4rem;
            background-color: $blue-100;
            border-radius: 4rem;
           
        }
    }


    
}
.buttonAddDocs{
    width: fit-content;
    border-radius: 12rem;
    height: 24rem;
}
