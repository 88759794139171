@use "../../scss/" as *;

.mapContainer{
    width: 100%; 
    height: 90vh; 
    position: relative;
}

.mapStyle{
    width: 100%;
    height: 100%;
}

.buttonsPenContainer{
    background-color: $white;
    position: absolute;
    top: 12rem;
    right: 12rem;
    padding: 12rem;
    box-shadow: 0rem 4rem 16rem 0rem rgba(0, 16, 61, 0.16);
    border-radius: 12rem;

    .buttonPen{
        border: 1rem solid $border-color;
        background-color: $gray-20;
        padding: 8rem !important;
        transition: .3s linear;
        cursor: pointer;

        &.active{
            background-color: $blue;
        }
    
        object{
            margin: 0;
            pointer-events: none;
            cursor: pointer;
        }
    }

}
.buttonsEditContainer{
    background-color: $white;
    position: absolute;
    top: 12rem;
    right: 100rem;
    padding: 12rem;
    box-shadow: 0rem 4rem 16rem 0rem rgba(0, 16, 61, 0.16);
    border-radius: 12rem;

    .buttonPen{
        border: 1rem solid $border-color;
        background-color: $gray-20;
        padding: 8rem !important;
        transition: .3s linear;
        cursor: pointer;

        &.active{
            background-color: $blue;
        }
    
        object{
            margin: 0;
            pointer-events: none;
            cursor: pointer;
        }
    }

}

.buttonsNavMap{
    position: absolute;
    right: 16rem;
    bottom: 40rem;
    display: flex;
    gap: 8rem;

    .saveMapButton{
        width: 160rem;
        height: 32rem;
        background-color: $green-400;
    }
    .closeMapButton{
        width: 160rem;
        height: 32rem;
        background-color: $white;
        outline: 1rem  solid $border-color;
        color: $txt-color;
    }
}

.poligon{
    pointer-events: none;
}

.tooltip {
    background-color: white;
    border-radius: 8rem;
    padding: 8px;
    z-index: 1000;
}

.tooltipContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 8rem;

    p{
        @include fnt(12, 24, $color: $txt-color!important, $font: $fontText) ;
    }
}


@media (max-width: 600px) {
    .mapContainer{
        height: 80vh;
    }
    .mapPageContainer.active .mapStyle .ymaps-2-1-79-map{
        width: 100vw !important;
    }
}