@use "./variables" as *;
@mixin flex($aligin-item, $justify-content) {
    display: flex;
    align-items: $aligin-item;
    justify-content: $justify-content;
}

@mixin overlay {
    background-color: rgba($color: #000000, $alpha: 0.6);
}

@mixin fnt($size: 16, $height: 24, $color: $black, $weight: 400, $font: $fontUI, $opacity: 1) {
    font-family: $font;
    font-style: normal;
    font-variation-settings: "wght" $weight;
    font-size: #{$size}rem;
    line-height: #{$height}rem;
    color: $color;
    opacity: $opacity;
}

@mixin size($width: 24, $height: false) {
    width: #{$width}rem;
    @if ($height) {
        height: #{$height}rem;
    } @else {
        height: #{$width}rem;
    }
}

@mixin scroll() {
    scrollbar-width: 4rem;
    scrollbar-color: transparent;
    &::-webkit-scrollbar {
        width: 4rem;
        height: 4rem;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2rem;
        background-color: $gray-500 !important;
    }
    &::-webkit-scrollbar-button {
        height: 0;
        width: 0;
    }
    // scrollbar-gutter: stable;
}

@mixin btn($bg-color: $blue, $color: #fff) {
    @include fnt(14, 20, $color !important, 600);
    display: flex;
    padding: 8rem 14rem;
    background-color: $bg-color;
    border-radius: 8rem;
    width: fit-content;
    transition:
        background-color 0.2s ease-out,
        color 0.2s ease-out;
    cursor: pointer;
    border: none;
    &:hover {
        text-decoration: none;
        color: $color !important;
        background-color: darken($bg-color, 5%);
    }
    &._block {
        width: unset;
        justify-content: center;
    }
    &._disabled {
        pointer-events: none;
        opacity: 0.2;
    }
}
