@use "../../scss/" as *;

.backGray {
    background-color: $gray-50;
    padding: 16rem;
    border-radius: 12rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 16rem;
}

.headerFounder {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .closeButton {
        cursor: pointer;
        transition: 0.3s linear;

        &:hover {
            background-color: $white;
            border-radius: 4rem;
        }
    }
}

.whiteButton {
    background-color: transparent;
    width: 100%;
    border: 1rem solid $blue-100;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8rem;
    @include fnt(14, 20, $blue-600, 500);

    &:hover {
        background-color: $blue-25;
    }
    &._disable {
        color: $gray-400 !important;
        background-color: $gray-100;
        border: 1rem solid $border-color !important;
        cursor: unset;
        &:hover {
            background-color: $gray-100;
        }
    }

    object {
        width: 16rem;
        height: 16rem;
        margin-right: 4rem;
    }
}
