@use "../../../scss/" as *;


.file-picker-container {
    display: flex;
    flex-direction: column;
}

.file-picker-modal {
    width: 100%;
    height: 126rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $gray-20;
    border-radius: 4rem;
    padding: 16rem;
    position: relative;
    &.active{
      border: 2rem solid $black-blue;
    }

    .textUploadContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      grid-gap: 8rem;

      img{
        pointer-events: none;
        width: 32rem;
        height: 32rem;

      }
    }

    .textUpload{
      width: 380rem;
      text-align: center;

      @include fnt(14, $weight: 600, $color: $black-btn, $height: 20);
      display: flex;
      flex-direction: column;

      b{
        @include fnt(14, $weight: 400, $color: $blue, $height: 20);

        margin-right: 4rem;
      }
      span{
        @include fnt(12, $weight: 400, $color: $text-gray, $height: 20);
      }
    }
  }

.file-picker-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.file-picker-button {
    position: absolute;
    bottom: 80rem;
}