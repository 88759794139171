@use "../../scss/" as *;

.continerLandplotPage{
    padding-top: 16rem;
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr) !important; /* Создаем три колонки */
    gap: 16rem;

    .inputContainerCol{
        display: grid;
        flex-direction: column;
        gap: 16rem;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        .microTitle{
            padding: 0 12rem;
            @include fnt(12, 20, $weight: 500, $font: $fontUI, $color: $text-gray);
        }
    }

    .fromBlockGray{
        padding: 16rem;
        background-color: $gray-20;
        border-radius: 8rem;
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }

    .textToggle{
        
        @include fnt(16!important, 16!important, $color: $txt-color!important, $font: $fontText !important) ;
        margin: 0;
    }
    .containerToggle{
        align-items: center;
    }


    .saveButton{
        position: fixed;
        right: 44rem;
        bottom: 44rem;
        border-radius: 8rem;
        background: var(--Cyan, #0CC);
        box-shadow: 0rem 4rem 12rem 0rem rgba(0, 0, 0, 0.15);
        padding: 8rem 12rem 8rem 8rem;
    }

    .contentBlockGray {
        display: grid;
        gap: 16rem;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
    }

    .visibleBlock{
        display: none;
    }
    .visibleBlockItem{
        display: flex;
    }

    
    .col-1 {
        grid-column: 1; 
    }
    
    .col-2 {
        grid-column: 2;
    }
    
    .col-3 {
        grid-column: 1 / span 2;
    }
    .blockFlex{
        display: grid;
    }

    .deleteButton{
        border: 1rem solid #ed0a34;
        background-color: #ffffff;
        color: #ed0a34;
        height: 32rem;
    
    }

    .buttonAdd{
        margin-top: 16rem;
    }
}

.mapContainerLandploat{
    // height: 320rem;
    border-radius: 8rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .continerLandplotPage{
        padding-top: 16rem;
        display: flex !important;
        flex-direction: column !important;
        padding-bottom: 48rem;
        .inputContainerCol{
            display: flex;
    
            
        }
    }
}