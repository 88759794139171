@use "../../scss/" as *;
.headerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48rem;
    position: relative;
    padding: 0 24rem;
    &::after {
        content: '';
        display: block;
        width: calc(100% - 48rem);
        position: absolute;
        top: 100%;
        height: 1px;
        background-color: $border-color;
    }
    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4rem;
        padding-right: 12rem;
        img {
            display: block;
            border-radius: 50%;
            margin-right: 8rem;
            padding: 4rem;
            background-color: $gray-300;
            cursor: pointer;
        }
        p {
            @include fnt(16, 24,$gray-900, 600);
            margin: 0;
        }
    }
  
}
