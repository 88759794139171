@use "../../scss/" as *;

.modalContainerBg{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(5, 13, 51, 0.05);
    cursor: pointer;
}

.modal{
    max-width: 468rem;
    width: 100%;
    border-radius: 12rem;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);

    .imgItem{
        width: 100%;
        height: 500rem;
        overflow: hidden;
        object-fit: cover;
    }

    .slick-dots{
        bottom: 10rem;
    }
}

@media (max-width: 600px) {
   .modal{
    transform: translate(-50%, -50%) scale(0.8);
   }
}