@use "../../scss/" as *;
.header {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 16rem 24rem;
    width: 100%;
    background-color: $white;
    z-index: 1;
    gap: 12rem;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $wg-gray;
        border-radius: 4rem;
        padding-right: 12rem;
        img {
            display: block;
            background-color: $gray-iron-700;
            border-radius: 4rem;
            margin-right: 8rem;
            padding: 4rem;
            width: 32rem;
            height: 32rem;
        }

        .headerContentText{
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                @include fnt(16, 24, $txt-color, 600, $font: $fontText);
                margin: 0;
                white-space: nowrap;
            }
    
            .number{
                margin-left: 8rem;
                padding-left: 8rem;
                position: relative;
                @include fnt(12, 22, $text-gray, 400, $font: $fontText);
    
                &::after{
                    content: '';
                    width: 1rem;
                    height: 80%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    background: $border-color;
                }
            }
        }
       
    }

    &::after{
        content: '';
        width: 97%;
        height: 1rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $border-color;
    }
    // @media(max-width: 1200px) {
    //     width: 100%;
    //     &.active{
    //         width: auto;
    //     }
    // }

}


.header__title{
    // min-width: 212rem;
    justify-content: flex-start;
  }

@media(max-width: 600px) {
    .header{
        flex-direction: column;
        padding: 8rem 12rem;
       &.active{
        width: 100%;
       }
       .button__container{
            height: 30rem;
            @include fnt(10, 12, $white, 400, $font: $fontText);
       }

       .header__title{
         width: 100%;
       }
    }
}
