@use "../../scss/" as *;

.continerPage{
    padding: 16rem 24rem; /* Убираем правый и левый отступ, оставляем верхний и нижний */
    display: grid;
    flex-direction: column;
    gap: 16rem;
    grid-template-columns: 1fr 1fr 1fr; /* Создаем три колонки */

    .inputContainerCol{
        display: grid;
        flex-direction: column;
        gap: 16rem;
        grid-template-columns: 1fr 1fr;

        .col-3{
            .microTitle{
                margin-left: 12rem;
                @include fnt(12, 20, $color: $text-gray, $weight: 500);
            }
            .titleBlock{
                @include fnt(16, 24, $color: $txt-color!important, $font: $fontText !important) ;
            }
        }
    }

    .fromBlockGray{
        padding: 16rem;
        background-color: $gray-20;
        border-radius: 8rem;
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }

    .textToggle{
        @include fnt(16, 16, $color: $txt-color!important, $font: $fontText !important) ;
        margin: 0;
    }
    .containerToggle{
        align-items: center;
    }


    .saveButton{
        position: fixed;
        right: 44rem;
        bottom: 44rem;
        border-radius: 8rem;
        background: var(--Cyan, #0CC);
        box-shadow: 0rem 4rem 12rem 0rem rgba(0, 0, 0, 0.15);
        padding: 8rem 12rem 8rem 8rem;
    }

    .contentBlockGray {
        display: grid;
        gap: 16rem;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
    }

    .visibleBlock{
        display: none;
    }
    .visibleBlockItem{
        display: flex;
    }

    
    .col-1 {
        grid-column: 1; 
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }
    
    .col-2 {
        grid-column: 2;
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }
    
    .col-3 {
        grid-column: 1 / span 2;
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }
    .blockFlex{
        display: grid;
    }
    .deleteButton{
        border: 1rem solid $red;
        background-color: $white;
        color: $red;
        height: 32rem;
    }

   
}

.addCoordinats{
    margin-top: 16rem;
}


.w-100{
    .inputContainer {
        gap: 8rem;
        .flexInput{
            width: 100%;
        }
      
    }
}

.deleteButtonInput{
    background-color: inherit;
    padding: 6rem !important;
    border: 1rem solid $red;
    border-radius: 8rem;

    object{
        margin: 0;
    }

    &:hover{
        background-color: $gray-100;
    }
}

.addInputButton{
    background-color: inherit;
    border: 1rem solid $lg-blue-100;
    padding: 6rem 12rem !important;
    max-width: fit-content;
    border-radius: 8rem;

    @include fnt(14, 20, $color: $blue-700, $weight: 500);

    &:hover{
        background-color: $gray-100;
    }
}

@media (max-width: 600px) {
    .continerPage{
        padding: 8rem 12rem;
        grid-template-columns: auto;
        .inputContainerCol{
            display: flex;
            max-width: inherit;
        }
    }
    .roleContainerButton{
        flex-direction: column;
        padding: 4rem !important;
        .buttonRole{
            margin: 0 !important;
        }
    }
    .file-picker-modal .textUpload{
        width: 100% !important;
    }

    .continerPage .saveButton{
        right: 20rem;
        bottom: 64rem;
    }
}