@use "../../../scss/" as *;

.containerImg{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4rem;
    max-width: 366rem;
    margin-top: 12rem;
    margin-bottom: 12rem;
}

.bigContainer{
    max-width: 100%;
    margin-top: 8rem;
}



.imgContainerItem{
    position: relative;
    width: 64rem;
    height: 64rem;
    .imgItem{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .buttonClose{
        position: absolute;
        right: 0;
        top: 0;
        width: 20rem;
        height: 20rem;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(44, 45, 46, 0.50);
        border-bottom-left-radius: 8rem;
        cursor: pointer;

        img{
            width: 16rem;
            height: 16rem;
            object-fit: contain;
        }
    }
}

.bigContainerItem{
    width: 100%;
    height: 96rem;
}

.buttonUpload{
    width: 96rem;
    height: 96rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $gray-100;
    cursor: pointer;

    .textUploadButton{
        @include fnt(10, $color: $gray-400)
    }
}