@use "../../scss/" as *;

.continerLandplotPage{
    padding-top: 16rem;
    display: grid;
    flex-direction: column;
    grid-template-columns: 396rem 1fr 1fr; /* Создаем три колонки */

    .inputContainerCol{
        display: grid;
        flex-direction: column;
        gap: 16rem;
        grid-template-columns: 1fr 1fr;
    }

    .fromBlockGray{
        padding: 16rem;
        background-color: $gray-20;
        border-radius: 8rem;
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }

    .textToggle{
        @include fnt(16, $color: $txt-color);
        margin: 0;
    }
    .containerToggle{
        align-items: center;
    }


    .saveButton{
        position: fixed;
        right: 44rem;
        bottom: 44rem;
        border-radius: 8rem;
        background: var(--Cyan, #0CC);
        box-shadow: 0rem 4rem 12rem 0rem rgba(0, 0, 0, 0.15);
        padding: 8rem 12rem 8rem 8rem;
    }

    .contentBlockGray {
        display: grid;
        gap: 16rem;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;
    }

    .visibleBlock{
        display: none;
    }
    .visibleBlockItem{
        display: flex;
    }

    
    .col-1 {
        grid-column: 1; 
    }
    
    .col-2 {
        grid-column: 2;
    }
    
    .col-3 {
        grid-column: 1 / span 2;
    }
    .blockFlex{
        display: grid;
    }

    .deleteButton{
        border: 1rem solid #ed0a34;
        background-color: #ffffff;
        color: #ed0a34;
        height: 32rem;
    
    }
}

@media (max-width: 600px) {
    .continerLandplotPage{
        padding-top: 16rem;
        grid-template-columns: auto;
        .inputContainerCol{
            display: flex;
            max-width: inherit;
        }
    }
    .roleContainerButton{
        flex-direction: column;
        padding: 4rem !important;
        .buttonRole{
            margin: 0 !important;
        }
    }
    .file-picker-modal .textUpload{
        width: 100% !important;
    }

    .continerLandplotPage .saveButton{
        right: 20rem;
        bottom: 64rem;
    }
}