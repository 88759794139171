@use "../../scss/" as *;

.modalContainerBg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(5, 13, 51, 0.2);
    cursor: pointer;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    width: 1212rem;
    height: 700rem;
    background-color: $white;
    padding: 24rem;
    border-radius: 16rem;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);
}

.mapHeader {
    display: flex;
    margin-bottom: 16rem;

    h1 {
        @include fnt(20, $color: $txt-color, $weight: 600);
    }
    h3 {
        @include fnt(20, $color: $sg-gray, $weight: 400);
        margin-left: 8rem;
    }
}

.modalMap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8rem;
    border: 1px solid $border-color;
}
.modalMapStyle {
    width: 100%;
    height: 615rem;
    border-radius: 16rem;
    overflow: hidden;
}

.modalMapContainer {
    flex-grow: 1;
    display: contents;
}
