@use "../../scss/" as *;

.inputContainerColPayment{
    display: flex;
    flex-direction: column;
    gap: 32rem;

    .inputContainerCol{
        .inputContainerColTitle {
            display: flex;
            flex-direction: column;
            @include fnt(16, 24, $font: $fontText);

            span {
                @include fnt(12, 16, $color: $text-gray, $font: $fontText);
            }
        }
    }
   
}

.inputContainerButton{
    display: grid;
    gap: 4rem;
    align-items: end;
    grid-template-columns: 1fr 80rem;

    .whiteButton{
        color: $blue-700;
        height: 36rem;
    }
}