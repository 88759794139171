@use "../../../scss/" as *;
.file-picker-overlay {
    position: fixed;
    background-color: rgba(0, 16, 61, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-picker-container {
    display: flex;
    flex-direction: column;
}

.file-picker-modal {
    width: 100%;
    height: 126rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    padding: 16rem;
    position: relative;

    &.active{
      border: 2rem solid $black-blue;
    }

    .textUploadContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .textUpload{
      width: 380rem;
      text-align: center;

      b{
        color: $black-blue;
        margin-right: 4rem;
      }
    }
  }

.file-picker-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.file-picker-button {
    position: absolute;
    bottom: 80rem;
}