@use "../../scss/" as *;

.continerCommentPage{
    padding-top: 16rem;
    display: grid;
    flex-direction: column;
    height: 80vh;
    grid-template-columns: 1fr 1fr; /* Создаем три колонки */

    .blockComments{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8rem;
        padding-bottom: 40rem;
        .containerComment{
            display: flex;
            width: 100%;
        }
        .commentItem{
            width: fit-content;
            height: fit-content;
            border-radius: 4rem;
            display: flex;
            flex-direction: column;
            gap: 8rem;
            width:fit-content;
            padding: 4rem;
            // border: 1rem solid $gray-300;
            
            .avatarUser{
                border-radius: 50%;
                height: 32rem;
                width: 32rem;
            }

            .commentContent{
                display: flex;
                flex-direction: column;
                gap: 4rem;
                
                width: 100%;
            }

            .nameUser{
                margin: 0;
                @include fnt(14, 16, $weight: 600, $font: $fontText, $color: $txt-color);
            }

            .textComment{
                margin: 0;
                padding: 0;
                @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $txt-color);
            }
            .timeComment{
                margin: 0;
                padding: 0;
                @include fnt(12, 16, $weight: 500, $font: $fontUI, $color: $text-gray);
            }

            .footerComment{
                display: flex;
                gap: 4rem;

                span{
                    cursor: pointer;
                    @include fnt(12, 16, $weight: 500, $font: $fontUI, $color: $blue);

                    &:hover{
                        color: $blue-700;
                    }
                }

                &.active{
                    flex-direction: column;
                }
            }
            &.highlighted{
                background-color: $black;
            }
        }
        .commentItemAnsver{
            width: fit-content;
            height: fit-content;
            border-radius: 4rem;
            flex-direction: column;
            display: flex;
            gap: 4rem;
            width: 100%;
            .avatarUser{
                border-radius: 50%;
                height: 32rem;
                width: 32rem;
            }

            .commentContent{
                display: flex;
                flex-direction: column;
                gap: 4rem;
            }

            .nameUser{
                margin: 0;
                @include fnt(14, 16, $weight: 600, $font: $fontText, $color: $txt-color);
            }

            .textComment{
                margin: 0;
                padding: 0;
                @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $txt-color);
            }
            .timeComment{
                margin: 0;
                padding: 0;
                @include fnt(12, 16, $weight: 500, $font: $fontUI, $color: $text-gray);
            }

            .footerComment{
                display: flex;
                gap: 4rem;

                span{
                    cursor: pointer;
                    @include fnt(12, 16, $weight: 500, $font: $fontUI, $color: $blue);

                    &:hover{
                        color: $blue-700;
                    }
                }

                &.active{
                    flex-direction: column;
                }
            }
        }

        .fileComment{
            display: flex;
            align-items: center;
            cursor: pointer;
         
            p{
                margin: 0;
                padding: 0;
                @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $blue);
                max-width: 300rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:hover{
                p{
                    color: $blue-700;
                }
            }
        }

        .inputMessage{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 40rem 40rem;
            gap: 4rem;
            align-items: start;
            border-bottom: 1rem solid $gray-300;
            padding-bottom: 12rem;

            .inputContainer .leftIconInpit{
                right: 0;
                margin-right: 8rem;
                transform: rotateZ(180deg);
            }

            .button__container.iconContainer{
                padding: 8rem;
                background-color: initial;

                object{
                    margin: 0;
                    transform: rotateZ(180deg);
                }
            }
        }
        p{
            padding: 8rem;
            margin: 0 auto;
            @include fnt(12, 24, $color: $text-gray);
        }
    }
    
    .col-1 {
        grid-column: 1; 
    }
    
    .col-2 {
        grid-column: 2;
    }
    
    .col-3 {
        grid-column: 1 / span 2;
    }
    .blockFlex{
        display: grid;
    }
}

.titleAddFile{
    @include fnt(14, 16, $weight: 600, $font: $fontText, $color: $txt-color);
    padding: 10rem 0;
}

