@use "../../scss" as *;

.checkbox{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  border-radius: 4rem;


  .checkboxMark{
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
  }
}

.checkFlex{
    display: flex;
    border-radius: 8rem;
    align-items: center;
    background-color: $gray-200;

    &.containerStages__0{
      background-color: rgba(102, 198, 28, 0.20) !important;
    }
    &.containerStages__1{
      background-color: rgba(234, 170, 8, 0.20) !important;
    }
    &.containerStages__2{
      background-color: rgba(239, 104, 32, 0.20) !important;
    }
    &.containerStages__3{
      background-color: rgba(34, 204, 238, 0.20) !important;
    }
    &.containerStages__4{
      background-color: rgba(7, 250, 250, 0.2) !important;
    }
    &.containerStages__5{
      background-color: rgba(112, 112, 123, 0.20) !important;
    }
   
}

.modalCheck{
  overflow: scroll;
  .checkFlex{
    background-color: $gray-100;
    justify-content: space-between;
    width: 100%;
    label{
      width: 100%;
      
      overflow: hidden;
      text-wrap: nowrap;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.checkFlex{
  display: flex;
  border-radius: 8rem;
  align-items: center;
     
  &.style_5{
    background-color: rgba(34, 204, 238, 0.20);
    margin-bottom:0;
  }
  &.style_1{
    background-color: rgba(102, 198, 28, 0.20);
    margin-bottom:0;
   
  }
  &.style_2{
    background-color: rgba(234, 170, 8, 0.20);
    margin-bottom:0;
  }
  &.style_3{
    background-color: rgba(239, 104, 32, 0.20);
    margin-bottom:0;
  }
  &.style_4{
    background-color: rgba(112, 112, 123, 0.20);
    margin-bottom:0;
  }
  &.style_key_9 {
    background-color: rgba(141, 84, 255, 0.2);
  }
}

.checkbox-container {
    display: flex;
    align-items: center;
    width: 100%;
 
}

.checkbox-input {
    appearance: none;
    width: 16rem;
    height: 16rem;
    border: 2rem solid $gray-400;
    border-radius: 5rem;
    cursor: pointer;
    transition: border-color 0.3s linear;
    position: relative;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &.style_5{
      border-color: #2CE;
    }
    &.style_1{
      border-color: #66C61C;
     
    }
    &.style_2{
      border-color: #EAAA08;
    }
    &.style_3{
      border-color: #EF6820;
    }
    &.style_4{
      border-color: #70707B;
    }
    &.style_key_9 {
      border-color: rgb(141, 84, 255);
    }

    &.containerStages__0{
      border-color: #66C61C !important;
    }
    &.containerStages__1{
      border-color: #EAAA08 !important;
    }
    &.containerStages__2{
      border-color: #EF6820 !important;
    }
    &.containerStages__3{
      border-color: #2CE !important;
    }
    &.containerStages__4{
      border-color: #0CC !important;
    }
    &.containerStages__5{
      border-color: #70707B !important;
    }
}

.checkbox-input:checked {
    border-color: $blue;
    background-color:$blue;

    &.style_5{
      border-color: #2CE;
      background-color: #2CE;
    }
    &.style_1{
      border-color: #66C61C;
      background-color: #66C61C;
     
    }
    &.style_2{
      border-color: #EAAA08;
      background-color: #EAAA08;
    }
    &.style_3{
      border-color: #EF6820;
      background-color: #EF6820;
    }
    &.style_4{
      border-color: #70707B;
      background-color: #70707B;
    }
    &.style_key_9 {
      border-color: rgb(141, 84, 255);
      background-color: rgb(141, 84, 255);
    }

    &.containerStages__0{
      border-color: #66C61C !important;
      background-color: #66C61C !important;
    }
    &.containerStages__1{
      border-color: #EAAA08 !important;
      background-color: #EAAA08 !important;
    }
    &.containerStages__2{
      border-color: #EF6820 !important;
      background-color: #EF6820 !important;
    }
    &.containerStages__3{
      border-color: #2CE !important;
      background-color: #2CE !important;
    }
    &.containerStages__4{
      border-color: #0CC !important;
      background-color: #0CC !important;
    }
    &.containerStages__5{
      border-color: #70707B !important;
      background-color: #70707B !important;
    }
 
}

/* Стили для галочки */


.checkbox-input:checked + .checkboxMark{
    opacity: 1; 
  }

.checkbox-label {
    cursor: pointer;
    margin-left: 6rem;
    @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $txt-color)
}

.checkboxCount{
  margin: 0 6rem;
  @include fnt(14, 16, $weight: 400, $font: $fontText, $color: rgba(44, 45, 46, 0.50))
}

// .checkbox-label.checked {
//     color:$blue;
// }

