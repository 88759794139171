@use "../../scss/" as *;

.MapComponent {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 8rem;
    border: 1px solid $border-color;
    &._modal {
        width: 100%;
        height: 100%;
    }
    &._form {
        width: 100%;
        height: 600px;
        margin-top: 20rem;
    }
    .loadingFull {
        height: 100%;
    }
}
