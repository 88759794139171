@use "../../scss" as *;

.filterButton {
  background-color: #0cc;
  padding: 4rem 12rem 4rem 8rem !important;

  &:hover {
    opacity: 0.8;
    background-color: #0cc;
  }
}

.regionContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12rem;
}

.containerHeaderSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInput {
  // margin-left: 8rem;
  flex-grow: 1;
  .flexInput {
    .formInput {
      height: 32rem;
      border-radius: 4rem 0 0 4rem;
    }
  }
}
.buttonSearch {
  border-radius: 0 4rem 4rem 0;
  padding: 4rem 6rem !important;
  object {
    margin: 0;
  }
}

.containerModal {
  background-color: $white;
  padding: 28rem 32rem 32rem;
  display: flex;
  flex-direction: column;
  grid-gap: 16rem;
  max-height: 700rem;

  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;

  &.filter {
    max-height: 750rem;
    overflow: scroll;
  }

  h1 {
    @include fnt(20, 24, $weight: 600, $font: $fontText, $color: $txt-color);
  }
  p {
    @include fnt(14, 20, $weight: 400, $font: $fontText, $color: $txt-color);
  }

  .modalToggle {
    display: flex;
    grid-gap: 24rem;

    p {
      line-height: 16rem;
    }
  }

  .file-picker-modal {
    width: 100%;
    background-color: $gray-20;
  }

  .modalButtonContainer {
    display: flex;
    flex-direction: column;
    grid-gap: 8rem;

    .greyButton {
      background-color: inherit;
      @include fnt(14, 16, $weight: 500, $color: $red);
    }
  }
}

.addPageTabs {
  margin: 20rem auto;
  border: 1rem solid $gray-300;
  background-color: $white;
  padding: 4rem 48rem 4rem 40rem !important;
  @include fnt(12, 16, $weight: 400, $color: $txt-color);

  &:hover {
    background-color: $white;
  }

  &.active {
    object {
      transition: 3s linear;
      transform: translateZ(360deg);
    }
  }
}

.p-button{
  svg{
    display: none;
  }
  border-radius: 4rem;
  .p-button-label{
    @include fnt(14, 24, $weight: 400, $color: $white);
  }
}

.filterButton {
  border-radius: 4rem;
}