@use "../../scss/" as *;

.loadingFull{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $gray-200;

    object{
        width: 324rem;
        height: 324rem;
    }
}

@media (max-width: 600px) {
    .loadingFull{
        width: 100vw;
    }
    object{
        transform: scale(.6);
    }
}
  
  