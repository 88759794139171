@use "../../scss/" as *;

.containerHistory{
    display: flex;
    flex-direction: column;
    grid-gap: 16rem;
    max-width: 1300rem;
    overflow: hidden;

    .smaller-width {
        max-width: 350rem; /* Укажите желаемую ширину */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
}

.historyContain{
    display: flex;
    grid-gap: 6rem;
    p{
        @include fnt(14, 24, $weight: 400, $font: $fontText, $color: $text-gray)
    }
    
    b{
        @include fnt(14, 24, $weight: 600, $font: $fontText)
    }
}

.dateHistory{
    @include fnt(14, 24, $weight: 400, $font: $fontText)
}


.hsitoryContainText{
    display: flex;
    grid-gap: 6rem;

 
}

.PirsTab__block-title {
    display: block;
    @include fnt(16, 24, $black, 700);
    margin-bottom: 16rem;
}